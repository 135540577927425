<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Edit Role</h4>
							</CCol>
							
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Nama Role <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Nama Role" v-model="name">
							</div>
							<div class="form-group">
								<label for=""><b>Ditjen <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Ditjen" v-model="ditjen">
							</div>
							<div class="form-group">
								<label for=""><b>Slug <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Slug" v-model="slug">
							</div>
              <div class="form-group">
                <label for=""><b>Role Group </b></label>
                <v-select
                  :options="list_role_group"
                  label="group_name"
                  v-model="role_group_id"
                  placeholder="pilih group"
                ></v-select>
              </div>
              <br />
              <div class="form-group">
                <label><b>Hak Akses:</b></label>
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      v-model="access.is_dashboard_tp_access"
                    />
                    Akses Dashboard Tanaman Pangan
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      v-model="access.is_dashboard_bun_access"
                    />
                    Akses Dashboard Perkebunan
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      v-model="access.is_dashboard_horti_access"
                    />
                    Akses Dashboard Hortikultura
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      v-model="access.is_dashboard_sdg_access"
                    />
                    Akses Dashboard Sumber Daya Genetik
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      v-model="access.is_dashboard_hpt_access"
                    />
                    Akses Dashboard Benih Tanaman Pakan Ternak
                  </label>
                </div>
              </div>
              <br />
              <hr />
              <div class="form-group">
                <div
                  class="checkbox"
                  v-for="permission in permissions"
                  :key="permission.id"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="selectedPermissions"
                      :value="permission.id"
                      :id="'perm-' + permission.id"
                    />
                    {{ permission.permission_name }}
                  </label>
                </div>
              </div>
            </CCardBody>
            <CCardFooter>
              <div class="text-right">
                <button
                  type="submit"
                  class="btn btn-primary text-white btn-hover-outline-primary"
                >
                  <i class="fa fa-save"></i> Simpan
                </button>
              </div>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
	export default {
		name: "EditRole",
		data() {
			return {
				name: '',
				ditjen: '',
				slug: '',
      role_group_id: "",
      tmp_role_group_id: "",
      list_role_group: [],
				idRole: this.$route.params.idRole,
      access: {
        is_dashboard_tp_access: false,
        is_dashboard_bun_access: false,
        is_dashboard_horti_access: false,
        is_dashboard_sdg_access: false,
        is_dashboard_hpt_access: false,
      },
      permissions: [],
      selectedPermissions: [],
			};
		},
		methods: {
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				axios.put(
                    this.apiLink + "api/crud/role",
                    {
                        id: this.idRole,
                        name: this.name,
						ditjen: this.ditjen,
						slug: this.slug,
            role_group_id: this.role_group_id.id,
            permissions: this.selectedPermissions,
            ...this.access,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master role segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllRole'});
							}
						});
					}
				});
			},
		},
		created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
           
            axios.get(this.apiLink + "api/crud/role", {
                params: {
                    id: this.idRole,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_role = response.data;
                var res_role_data = res_role.data[0].data[0];
                if (res_role.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_role.data.message
                    });
                }
                else{
                    Swal.close();
                    this.name = res_role_data.name;
                    this.ditjen = res_role_data.ditjen;
                    this.slug = res_role_data.slug;
                    this.tmp_role_group_id = res_role_data.role_group_id;
                              
                    this.access = {
                      is_dashboard_tp_access: res_role_data.is_dashboard_tp_access,
                      is_dashboard_bun_access: res_role_data.is_dashboard_bun_access,
                      is_dashboard_horti_access: res_role_data.is_dashboard_horti_access,
                      is_dashboard_sdg_access: res_role_data.is_dashboard_sdg_access,
                      is_dashboard_hpt_access: res_role_data.is_dashboard_hpt_access,
                    };
                    this.selectedPermissions = res_role_data.role_permission.map(
                      (p) => p.id
                    );
                }
            });

            // load data role group
            axios
              .get(this.apiLink + "api/crud/role-group", {
                headers: {
                  Authorization: "Bearer " + this.access_token,
                },
              })
              .then((response) => {
                var res_role = response.data;
                var res_role_data = res_role.data[0];
                if (this.tmp_role_group_id) {
                  const selectedGroup = res_role_data.find(
                    (x) => x.id == this.tmp_role_group_id
                  );
                  this.role_group_id = selectedGroup;
                }
                this.list_role_group = res_role_data;
              });
                
          axios
            .get(this.apiLink + "api/crud/role/get_permission", {
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              var result = response.data;

              this.permissions = result;
            });
        }
	};
</script>